import React, { useState } from 'react';
import logo from "../static/images/logo.png";
import { NavLink, useHistory } from 'react-router-dom';
import { UserCircle,SquaresFour,TextAlignRight,List } from "@phosphor-icons/react";
import { database, auth } from "./firebaseConfig";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavbarText,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
// import { Link } from 'react-scroll';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      isOpen: false,
      loggedIn: this.props.loggedIn,
      userData: null,
    };
  }

  componentDidMount() {
    // Fetch user data from the Realtime Database
    console.log("navbar hit");
    const currentUser = auth.currentUser;
    if (currentUser) {
      database
        .ref(`users/${currentUser.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          this.setState({ loggedIn: true });
          this.setState({ userData: data });
          this.props.onUpdate(true);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  handleLogout() {
    // const history = useHistory();
    const { loggedIn } = this.state.loggedIn;
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.

        console.log("Signed out successfully");
        this.setState({ loggedIn: false });
        this.setState({ userData: null });
        this.props.onUpdate(false);
        useHistory().push("/home");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error during logout:", error);
      });
  };
  render() {
  const screenWidth = window.innerWidth;

    const { userData } = this.state;
    console.log("data---", this.state.userData);
    if (this.state.userData === null) {
      const currentUser = auth.currentUser;
      if (currentUser) {
        database
          .ref(`users/${currentUser.uid}`)
          .once("value")
          .then((snapshot) => {
            const data = snapshot.val();
            this.setState({ userData: data });
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    }
    console.log("loggedinflag", this.state.loggedIn);
    if (userData === null && this.state.loggedIn === true) {

      return (
        // this.setState({name: this.state.userData.name})
        <div className='loading'>
          <img className='loadinggif' src={process.env.PUBLIC_URL + "/images/loading.gif"} />
        </div>
      );
    }

    // console.log(this.state.userData.name);
    const { loggedIn } = this.props.loggedIn;
    return (
      <div>
        <Navbar dark expand="md" fixed="top" className='navbarColour'>
        <NavbarBrand href="/" className="ml-auto logo-font">
        <img src={logo} className="logo-image" alt="Dreams Jewels" />
        
        </NavbarBrand>
        <NavbarToggler className='navbartoggle'  >
        {this.props.loggedIn && this.state.userData ? (
            // console.log("data---",this.state.userData);
            <React.Fragment>
              <NavbarBrand>
                <UncontrolledDropdown className=' ml-auto dropnav-link nav-font'>
                  <DropdownToggle nav left >
                    <UserCircle size={28} />
                  </DropdownToggle>
                  <DropdownMenu dark >
                    <DropdownItem>{userData.name}</DropdownItem>
                    <DropdownItem>{userData.company}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem><button className="logoutbtn" onClick={this.handleLogout}>
                      Logout
                    </button></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavbarBrand>
            </React.Fragment>
          ) : null}
          <button className='togglebtn'>
          <List size={28} onClick={this.toggle} />
          </button>
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>
        <div className='padding-nav'></div>
          <Nav className="me-auto" navbar>
          <NavItem>
                <NavLink activeClassName='navbarselectedclass' className='nav-link nav-font' to='/home'>HOME</NavLink>
              </NavItem>
              <NavItem>
                <NavLink activeClassName='navbarselectedclass' className='nav-link nav-font' to='/catalogue'>CATALOGUE</NavLink>
              </NavItem>
              <NavItem>
                <Link
                  className='nav-link nav-font showcursor'
                  // activeStyle={navfontselected}
                  activeClass="navbarselectedclass"
                  smooth to="/home#aboutUs"
                >
                  ABOUT US
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className='nav-link nav-font showcursor'
                  // activeStyle={navfontselected}
                  activeClass="navbarselectedclass"
                  smooth to="/home#services"
                >
                  SERVICES
                </Link>
              </NavItem>
              {this.props.loggedIn ? (
                <React.Fragment>
                  <NavItem>
                    <NavLink activeClassName='navbarselectedclass' className='ml-auto nav-link nav-font justify-content-end' to='/rewards'>REWARDS</NavLink>
                  </NavItem>
                </React.Fragment>
              ) : null
              }
              {!this.props.loggedIn ? (
                <React.Fragment>
                  <NavItem>
                    <NavLink activeClassName='navbarselectedclass' className='ml-auto nav-link nav-font justify-content-end' to='/login'>LOGIN</NavLink>
                  </NavItem>
                </React.Fragment>
              ) :
                null
              }
          </Nav>
          
          {screenWidth >= 767 && this.props.loggedIn && this.state.userData ? (
            // console.log("data---",this.state.userData);
            <React.Fragment>
              <NavbarBrand>
                <UncontrolledDropdown className=' ml-auto dropnav-link nav-font'>
                  <DropdownToggle nav left >
                    <UserCircle size={28} />
                  </DropdownToggle>
                  <DropdownMenu dark >
                    <DropdownItem>{userData.name}</DropdownItem>
                    <DropdownItem>{userData.company}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem><button className="logoutbtn" onClick={this.handleLogout}>
                      Logout
                    </button></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavbarBrand>
            </React.Fragment>
          ) : null}
        </Collapse>
      </Navbar>
      </div>
    );
  }
}
const navfontselected = {
  color: '#EDE9E6 !important',
};

