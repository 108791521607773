import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
// import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth, database } from '../firebaseConfig';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Button,
    Input
} from 'reactstrap';
const Signup = () => {
    const history = useHistory();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isTouchedEmail, setisTouchedEmail] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isTouchedPassword, setisTouchedPassword] = useState(false);
    const [signupInvalid, setsignupInvalid] = useState(false);
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [companylocation, setCompanylocation] = useState("");
    const [phone, setPhone] = useState("");
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isTouchedPhone, setisTouchedPhone] = useState(false);
    const [type, setType] = useState("Retailer");
    const [userid, setUserid] = useState("");
    const [emailverificationsent,setemailverificationsent] = useState(false);
    const [errorcode,seterrorcode] = useState("");
    const handleChangeEmail = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);

        const isValid = /\S+@\S+\.\S+/.test(emailValue);
        setIsValidEmail(isValid);
    };
    const handleBlurEmail = () => {
        setisTouchedEmail(true);
    };

    const handleChangePassword = (event) => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isValid = passwordRegex.test(passwordValue);
        setIsValidPassword(isValid);
    };
    const handleBlurPassword = () => {
        setisTouchedPassword(true);
    };
    const handleChangeName = (event) => {
        const nameValue = event.target.value;
        setName(nameValue);
    };
    const handleCompany = (event) => {
        const companyValue = event.target.value;
        setCompany(companyValue);
    };
    const handleCompanylocation = (event) => {
        const companylocationValue = event.target.value;
        setCompanylocation(companylocationValue);
    };
    const handleType = (event) => {
        const typeValue = event.target.value;
        setType(typeValue);
    };
    const handleChangePhone = (event) => {
        const phoneValue = event.target.value;
        setPhone(phoneValue);

        const isValid = /^[6-9]\d{9}$/.test(phoneValue);
        setIsValidPhone(isValid);
    };
    const handleBlurPhone = () => {
        setisTouchedPhone(true);
    };


    const onSubmit = async (e) => {
        var user;
        if (isValidEmail && isValidPassword) {
            e.preventDefault()
            await auth.createUserWithEmailAndPassword(email, password)
            .then(async(userCredential) => {
                user = userCredential.user;
                setUserid(user);
                await database.ref(`users/${user.uid}`).set({
                    userid: user.uid,
                    name,
                    company,
                    companylocation,
                    type,
                    email,
                    password
                })
                    .then(() => {
                        userCredential.user.sendEmailVerification()
                        .then(() => {
                        auth.signOut();
                        setemailverificationsent(true);
                        seterrorcode("");
                        })
                        .catch((error) => {
                        const errorMessage = error.message;
                            seterrorcode(errorMessage);
    
                        })
                    })
    
                    .catch((error) => {
                        setsignupInvalid(true);
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        seterrorcode(errorMessage);
                        console.log(errorMessage);
                    });
            })
            .catch((error) => {
                const errorMessage = error.message;
                seterrorcode(errorMessage);
            })

            

            
        }
    }

    return (
        <div className="mainbodyimg">
            <div class="conatiner">
                <div class="row">
                    <div class="col-12">
                        <div className='solidbglogin'>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <img src={process.env.PUBLIC_URL + "/images/loginimg.png"} class="img-fluid" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                {emailverificationsent ? 
                                <React.Fragment>
                                    <h3 className='linksent-text'>
                                        We have sent a verification Link. Once verified come back and just <Link className='logintextlink' to="/login">Login here</Link> with you Credentials.
                                    </h3>
                                    <h6 className='logintext'></h6>

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <h6 className='subheader-text'>SIGN UP</h6>
                                    <br></br>
                                    <Form style={{ paddingRight: "5%", paddingLeft: "5%", paddingBottom: "5%" }}>
                                        <FormGroup floating>
                                            <Input
                                                id="name"
                                                name="name"
                                                placeholder="Full Name"
                                                type="name"
                                                value={name}
                                                onChange={handleChangeName}
                                            />
                                            <Label for="name">Full Name</Label>
                                        </FormGroup>
                                        <FormGroup floating>

                                            <Input
                                                id="select"
                                                name="select"
                                                type="select"
                                                value={type}
                                                onChange={handleType}
                                            >
                                                <option>
                                                    Retailer
                                                </option>
                                                <option>
                                                    Trader
                                                </option>

                                            </Input>
                                        </FormGroup>
                                        <FormGroup floating>
                                            <Input
                                                id="company"
                                                name="company"
                                                placeholder="Company Name"
                                                type="company"
                                                value={company}
                                                onChange={handleCompany}
                                            />
                                            <Label for="name">Company Name</Label>
                                        </FormGroup>
                                        <FormGroup floating>
                                            <Input
                                                id="companylocation"
                                                name="location"
                                                placeholder="Company Location"
                                                type="location"
                                                value={companylocation}
                                                onChange={handleCompanylocation}
                                            />
                                            <Label for="companylocation">Company Location</Label>
                                        </FormGroup>
                                        <FormGroup floating>
                                            <Input
                                                id="phone"
                                                name="phone"
                                                placeholder="Phone Number"
                                                type="phone"
                                                value={phone}
                                                onChange={handleChangePhone}
                                                onBlur={handleBlurPhone}
                                                valid={isTouchedPhone && isValidPhone}
                                                invalid={isTouchedPhone && !isValidPhone}
                                            />
                                            <Label for="phone">
                                                Phone Number
                                            </Label>

                                            <FormFeedback valid>
                                                Sweet! that phone number is valid
                                            </FormFeedback>

                                            <FormFeedback invalid>
                                                Please enter a valid phone number
                                            </FormFeedback>
                                        </FormGroup>
                                        <FormGroup floating>
                                            <Input
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                value={email}
                                                onChange={handleChangeEmail}
                                                onBlur={handleBlurEmail}
                                                valid={isTouchedEmail && isValidEmail}
                                                invalid={isTouchedEmail && !isValidEmail}
                                            />
                                            <Label for="email">
                                                Email
                                            </Label>

                                            <FormFeedback valid>
                                                Sweet! that email is valid
                                            </FormFeedback>

                                            <FormFeedback invalid>
                                                Please enter a valid email
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup floating>
                                            <Input
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                value={password}
                                                onChange={handleChangePassword}
                                                onBlur={handleBlurPassword}
                                                valid={isTouchedPassword && isValidPassword}
                                                invalid={isTouchedPassword && !isValidPassword}
                                            />
                                            <Label for="password">Password</Label>

                                            <FormFeedback invalid>
                                                Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                                            </FormFeedback>

                                            <FormFeedback valid>
                                                Great you can proceed.
                                            </FormFeedback>
                                        </FormGroup>
                                        <center>
                                            {signupInvalid ?
                                                <React.Fragment>
                                                    <p className='invalidcred'>Error in Sign up</p>
                                                </React.Fragment>
                                                :
                                                null
                                            }

                                            <p className='logintext'>Have signed up? <Link className='logintextlink' to="/login">Login here</Link> .</p>
                                            {errorcode === "" ? 
                                        null :
                                        <React.Fragment>
                                        <p className='errortext'>{errorcode}</p>
                                        </React.Fragment>
                                        }
                                            <button className="loginbtn" onClick={onSubmit}  >
                                                Register
                                            </button>
                                        </center>
                                    </Form>
                                </React.Fragment>
                                }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Signup