import React, {useState, useEffect, forwardRef } from "react";
import { Skeleton } from "../Skeleton";
import ImageArray from "./ImageArray";
import ImageArrayMobile from "./ImageArrayMobile";
import ImageArrayTablet from "./ImageArrayTablet";
// import { useMediaQuery } from 'react-responsive';
import dreamslogo from "../static/images/dreams-logo.png"
import since1992 from "../static/images/since1992.png"
import { useHistory } from 'react-router-dom';
import { CaretRight } from "@phosphor-icons/react";
import ViewCatalogueBtn from "../components/ViewCatalogueBtn";
const ImageSequence = forwardRef(({ progress }, ref) => {
  // const history = useHistory();
  // const handleClick = () => {
  //   console.log("hittt");
  //   history.push('/'); // Replace '/your-route' with the desired route path
  // };
  const desktopImages = ImageArray(); 
  const tabletImages = ImageArrayTablet();
  const mobileImages = ImageArrayMobile();

  const screenWidth = window.innerWidth;
  let images;

  if (screenWidth <= 767) {
    // Mobile screen
    images = mobileImages;
  } else if (screenWidth <= 1023) {
    // Tablet screen
    images = tabletImages;
  } else {
    // Desktop screen
    images = desktopImages;
  }
  let index = Math.round(progress * 1 * (images.length - 1));

  if (images[index][1] !== undefined) {
    if (images[index][1] === "loading") {
      return <Skeleton width="100%" height="100%" />;
    } else {
      return images.map((item, i) => (
        <span
          ref={ref}
          key={i}
          style={{
            display: i !== index ? "none" : "block",
            height: "100%",
            width: "100%",
            backgroundImage: `url('${item[0] ? item[0].src : null}')`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        >
          {/* <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div class="container">
            <div class="row ">
              <div class="col-4 col-md-4 col-lg-4 col-sm-4"></div>
              <div class="col-4 col-md-4 col-lg-4 col-sm-4 d-flex align-items-center justify-content-center">
                <img src={dreamslogo} class="img-fluid" />

              </div>
              <div class="col-4 col-md-4 col-lg-4 col-sm-4"></div>
            </div>
            <div class="row">
              <center>
                <h0>DREAMS JEWELS</h0>
              </center>
            </div>
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12 d-flex align-items-center justify-content-center">
                <img src={since1992} class="img-fluid" style={{ paddingLeft: "30px", paddingRight: "30px" }} />
              </div>
            </div>
            <br></br>
          <br></br>
          <br></br>
            <center>
             <ViewCatalogueBtn />
            </center>
          </div> */}
        </span>
      ));
    }
  }
});

export default ImageSequence;
