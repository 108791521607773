import React from "react";
import SuggestionCard from './SuggestionCard';
const SingleProductSuggestion = ({ data, id }) => {
  const allKeys = Object.keys(data);
const filteredKeys = allKeys.filter(index => index !== id);
const randomKeys = filteredKeys.sort(() => Math.random() - 0.5).slice(0, 10);

  return (
    <div className="card-list">
      {/* {Object.keys(data).random().slice(0, 10).map(index => {
        if (index != id) {
          return (
          <SuggestionCard product = {data[index]} prodId = {index} />
          )
        }
      })} */}
      {randomKeys.map(index => (
      <SuggestionCard key={index} product={data[index]} prodId={index} />
    ))}
    </div>
  );
}

export default SingleProductSuggestion;