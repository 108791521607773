import React, { useEffect, useState } from 'react';
import { Certificate,HandCoins,SketchLogo,Users } from "@phosphor-icons/react";

const Services = () => {

  return (
<div >
  <hr className='servicesline'></hr>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 align-items-center justify-content-center">
       <center>
        <Certificate className='serviceslogo' size={50}/>
        <h6 className='servicestext'>AUTHENTICITY</h6>
        <p className='servicesparagraph'>All products comes along with certificates from well known laboratories.</p>
        </center> 
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 align-items-center justify-content-center">
      <center>
        <HandCoins className='serviceslogo' size={50}/>
        <h6 className='servicestext'>LOW MAKING CHARGES</h6>
        <p className='servicesparagraph'>Each piece is crafted to give it a signature finish,yet you get a wide range on diamond studded jewelery choices with low making charges.</p>
        </center> 
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 align-items-center justify-content-center">
      <center>
        <SketchLogo className='serviceslogo' size={50}/>
        <h6 className='servicestext'>LOW DIAMOND RATES</h6>
        <p className='servicesparagraph'>We guarantee you low Diamond rates as compared to market. We also provide loose Diamonds.</p>
        </center> 
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 align-items-center justify-content-center">
      <center>
        <Users className='serviceslogo' size={50}/>
        <h6 className='servicestext'>BUSINESS RELATIONSHIP</h6>
        <p className='servicesparagraph'>We will do our best to maintain the Business relationship and provide exciting offers as we grow.</p>
        </center> 
      </div>
    </div>
  </div>
  <hr className='servicesline'></hr>

</div>

  );
};

export default Services;
