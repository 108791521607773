import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Card from '../Card';
import Rings from './Rings';
import {
  NavItem,
} from 'reactstrap';
import { NavLink, Route, Switch } from 'react-router-dom';

const CatalogueTabs = ({catalogue}) => {
  const screenWidth = window.innerWidth;


  return (
    <div className="centered-tabs">
      <div className="tabs-wrapper">
        <NavLink className='nav-link nav-font ' to='/catalogue/rings/rings'>RINGS</NavLink>
        <NavLink className='nav-link nav-font ' to='/catalogue/bracelets/bracelets'>BRACELETS</NavLink>
        <NavLink className='nav-link nav-font ' to='/catalogue/earings/earings'>EARINGS</NavLink>
        <NavLink className='nav-link nav-font ' to='/catalogue/pendantsets/pendantsets'>PENDANT SETS</NavLink>
          <NavLink className='nav-link nav-font ' to='/catalogue/necklaces/necklaces'>NECKLACES</NavLink>
          {/* <NavLink className='nav-link nav-font ' to='/catalogue/tanmaniya/tanmaniya'>TANMANIYA</NavLink> */}
        <NavLink className='nav-link nav-font ' to='/catalogue/pendants/pendants'>PENDANTS</NavLink>
        <NavLink className='nav-link nav-font ' to='/catalogue/necklaceset/necklaceset'>NECKLACE SET</NavLink>

        
       
      </div>
    </div>
  );
};


export default CatalogueTabs;
