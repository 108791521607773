import React, { useEffect, useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import SingleProductSuggestion from "./SingleProductSuggestions";
import Modal from "../ModalImage/Modal";
import { Link, useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { database, auth } from '../firebaseConfig';
import {
    Button,
    Input
} from 'reactstrap';
const SingleProduct = ({ data }) => {
    const location = useLocation();

    const { productcategory, dataId } = useParams();
    console.log("data", data);

    const product = data[productcategory][dataId];
    console.log(product);

    const [disableinquirebtn, setdisableinquirebtn] = useState(false);
    const [inquireprod, setInquireprod] = useState("");
    const [inquiresuccesss, setinquiresuccess] = useState(false);
    const [inquirefail, setinquirefail] = useState(false);
    const [totalweight, setTotalWeight] = useState(0);
    const history = useHistory();
    useEffect(() => {
        // Scroll to the top when the component mounts or when the route changes
        window.scrollTo(0, 0);
    }, [location.pathname]);


    const handleInquireProd = (event) => {
        const value = event.target.value;
        setInquireprod(value);
    };

    if (!product) {
        history.push('/home');
        return null;
    }
    const inquire = async () => {
        const date = new Date();

        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
        let currentYear = date.getFullYear();

        let currentDate = `${currentDay}-${currentMonth}-${currentYear}`;

        const userid = auth.currentUser.uid;
        const productid = dataId;
        await database.ref(`inquiry/`).push({
            inquireprod,
            userid,
            productid,
            productcategory,
            date: currentDate
        })
            .then(() => {
                setdisableinquirebtn(true);
                setinquiresuccess(true);
            })
            .catch((error) => {
                setInquireprod(true);
            })
    }
    const whatsapplink = () => {
        const message = 'Hi I want to inquire for ' + product.name + '(product id : ' + dataId + ').';
        const whatsappurl = '//api.whatsapp.com/send?phone=918850286201&text=' + message;
        window.open(whatsappurl, "_blank");
    }
    var total = 0;
    return (
        <div className="mainbody">
            <div class="container" className="singleprodcontainer">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                    <Modal
                            image={product.image}
                            alt = {productcategory}
                        />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">

                        {/* Rings */}
                        {product.category === "rings" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/rings/rings', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/rings/rings`} className="singleproductcategorynav">Rings</Link></h6>
                            </React.Fragment>
                        ) : null}

                        {/* Bracelets */}
                        {product.category === "bracelets" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/bracelets/bracelets', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/bracelets/bracelets`} className="singleproductcategorynav">Bracelets</Link></h6>
                            </React.Fragment>
                        ) : null}

                        {/* Pendant Set */}
                        {product.category === "pendantsets" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/pendantsets/pendantsets', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/pendantsets/pendantsets`} className="singleproductcategorynav">Pendant Sets</Link></h6>
                            </React.Fragment>
                        ) : null}

                        {/* Earings */}
                        {product.category === "earings" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/earings/earings', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/earings/earings`} className="singleproductcategorynav">Earings</Link></h6>
                            </React.Fragment>
                        ) : null}

                        {/* necklaces */}
                        {product.category === "necklaces" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/necklaces/necklaces', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/necklaces/necklaces`} className="singleproductcategorynav">Necklaces</Link></h6>
                            </React.Fragment>
                        ) : null}
                        {/* necklaceset */}
                        {product.category === "necklaceset" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/necklaceset/necklaceset', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/necklaces/necklaces`} className="singleproductcategorynav">Necklace Set</Link></h6>
                            </React.Fragment>
                        ) : null}
                        {product.category === "tanmaniya" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/tanmaniya/tanmaniya', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/tanmaniya/tanmaniya`} className="singleproductcategorynav">Tanmaniya</Link></h6>
                            </React.Fragment>
                        ) : null}
                        {product.category === "pendants" ? (
                            <React.Fragment>
                                <h6 className="singleproductnav"><Link to={`/home`} className="singleproductcategorynav">HOME</Link> . <Link to={{ pathname: '/catalogue/pendants/pendants', state: { data: data }, }} className="singleproductcategorynav">CATALOGUE</Link> . <Link to={`/catalogue/pendants/pendants`} className="singleproductcategorynav">Pendants</Link></h6>
                            </React.Fragment>
                        ) : null}
                        <br></br>
                        <h1 className="singleproductheader">{product.name}</h1>
                        <p className="singleproductgoldclr"> Code : <span className="singleproductdetailsbold">{product.code || "contact us"}</span> </p>
                        <p className="singleproductdetaildesc">{product.description}</p>
                        {product.material === "Gold" ? (
                            <React.Fragment>
                                <p className="singleproductgoldclr">GOLD COLOR :
                                    {product.color && product.color.yellow === "true" ? (
                                        <React.Fragment>
                                            <span className="goldcircle"></span>
                                        </React.Fragment>
                                    ) : null}
                                    {product.color && product.color.pink === "true" ? (
                                        <React.Fragment>
                                            <span className="pinkgoldcircle"></span>
                                        </React.Fragment>
                                    ) : null}
                                    {product.color && product.color.white === "true" ? (
                                        <React.Fragment>
                                            <span className="whitegoldcircle"></span>
                                        </React.Fragment>
                                    ) : null}
                                </p>
                            </React.Fragment>
                        ) : null}

                        {product.material === "Platinum" ? (
                            <React.Fragment>
                                <p className="singleproductgoldclr">PLATINUM : <span className="whitegoldcircle"></span></p>

                            </React.Fragment>
                        ) : null}

                        <p className="singleproductgoldclr"> KARAT : <span className="singleproductdetailsbold">{product.karat}</span> </p>
                        <p className="singleproductgoldclr"> {product.category === "pendantsets" ? "Pendant" : product.category === "necklaceset" ? "Necklace" : null} {product.material} WEIGHT : <span className="singleproductdetailsbold">{product.goldweight} gms</span> </p>
                        {product.category === "pendantsets" || product.category === "necklaceset" ?
                            <p className="singleproductgoldclr"> Earings {product.material} WEIGHT : <span className="singleproductdetailsbold">{product.eargoldweight} gms</span> </p>
                            :
                            null}

                        <p className="singleproductgoldclr"> DIAMOND Details : <span className="singleproductdetailsregular">
                            {(product.diamonds  || []).map(diamond => {
                                total = total + parseFloat(diamond.diamondweight);
                                // setTotalWeight((prevTotalWeight) => prevTotalWeight + parseFloat(diamond.diamondweight));
                                return (
                                    <React.Fragment>
                                        <hr></hr>
                                        <div className="namevaluecontainer">
                                            <span>
                                                {diamond.diamtype}
                                            </span>
                                            <span>
                                                {diamond.diamondweight} Carat
                                            </span>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                            <hr></hr>
                        </span></p>

                        {product.category === "pendantsets" || product.category === "necklaceset" ? 
                         <p className="singleproductgoldclr"> Earings DIAMOND Details : <span className="singleproductdetailsregular">
                         {(product.eardiamonds || []).map(diamond => {
                             total = total + parseFloat(diamond.diamondweight);
                             // setTotalWeight((prevTotalWeight) => prevTotalWeight + parseFloat(diamond.diamondweight));
                             return (
                                 <React.Fragment>
                                     <hr></hr>
                                     <div className="namevaluecontainer">
                                         <span>
                                             {diamond.diamtype}
                                         </span>
                                         <span>
                                             {diamond.diamondweight} Carat
                                         </span>
                                     </div>
                                 </React.Fragment>
                             );
                         })}
                         {/* <hr></hr>
                         <div className="namevaluecontainer singleproductdetailsbold maincolor">
                             <span>
                                 Total WEIGHT
                             </span>
                             <span>
                                 {total.toFixed(3)} carat
                             </span>
                         </div>
                         <hr></hr> */}
                     </span></p>
                     :
                     null}
                        

                        <p className="singleproductgoldclr"><span className="singleproductdetailsregular">
                        {product.category === "pendantsets" || product.category === "necklaceset" ? <hr></hr> : null}
                            <div className="namevaluecontainer singleproductdetailsbold maincolor">
                                <span>
                                    Total WEIGHT
                                </span>
                                <span>
                                    {total.toFixed(3)} carat
                                </span>
                            </div>
                            <hr></hr>
                        </span></p>

                        {auth.currentUser ?
                            <React.Fragment>
                                <Input
                                    className="inquiretextarea"
                                    id="productdesc"
                                    name="desc"
                                    placeholder="eg: I am interested in this product and would like to know pricing.."
                                    type="textarea"
                                    value={inquireprod}
                                    onChange={handleInquireProd}
                                />
                                {inquiresuccesss ?
                                    <p className="successmsg">Inquire has been received by us successfully. Please wait for 1 bussiness day, we will reach out to you on your registered mail.</p>
                                    :
                                    null
                                }
                                {inquirefail ?
                                    <p className="errortext">There has been some error in sending the inquiry. Please try after sometime or contact us.</p>
                                    : null
                                }
                                <Button className="inquirebutton" disabled={disableinquirebtn} onClick={inquire}>INQUIRE <CaretRight /></Button>
                            </React.Fragment>
                            :
                            <button className="inquirebutton" onClick={whatsapplink}>INQUIRE FOR THIS PRODUCT <CaretRight /></button>
                        }


                        <div className="whiteinfobg">
                            <h6 className="contacttext">CONTACT US FOR MORE INFORMATION
                            </h6>
                        </div>
                        <br></br>

                        <p className="singleproductdetailsbold">+91 8850286201</p>
                        <p className="singleproductdetailsbold">+91 9892153550</p>
                    </div>
                </div>
                <h6 className='subheader-text' id='aboutus'>EXPLORE MORE</h6>

                {/* Rings */}
                {product.category === "rings" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>RINGS</h1>
                    </React.Fragment>
                ) : null}

                {/* Bracelets */}
                {product.category === "bracelets" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>BRACELETS</h1>
                    </React.Fragment>
                ) : null}

                {/* Pendant Sets */}
                {product.category === "pendantsets" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>PENDANT SETS</h1>
                    </React.Fragment>
                ) : null}

                {/* Earings */}
                {product.category === "earings" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>EARINGS</h1>
                    </React.Fragment>
                ) : null}

                {/* Necklaces */}
                {product.category === "necklaces" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>NECKLACES</h1>
                    </React.Fragment>
                ) : null}

                {/* Tanmaniya */}
                {product.category === "tanmaniya" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>TANMANIYA</h1>
                    </React.Fragment>
                ) : null}

                {/* Necklaces */}
                {product.category === "pendants" ? (
                    <React.Fragment>
                        <h1 className='mainheading-text'>PENDANTS</h1>
                    </React.Fragment>
                ) : null}


                <SingleProductSuggestion data={data[product.category]} id={dataId} />
            </div>

        </div>
    );
}

export default SingleProduct;

