import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyDF9exiFFpE6DkyyQmBbZKz9FHBzskI7YM",
  authDomain: "dreamsjewelsb2b.firebaseapp.com",
  databaseURL: "https://dreamsjewelsb2b-default-rtdb.firebaseio.com",
  projectId: "dreamsjewelsb2b",
  storageBucket: "dreamsjewelsb2b.appspot.com",
  messagingSenderId: "246196962315",
  appId: "1:246196962315:web:ead59c8d024cf810c1730e",
  measurementId: "G-75PN0F46DJ"
};

firebase.initializeApp(config);
export const database = firebase.database();
export const auth = firebase.auth();