import React, { useState, useEffect } from "react";
import Card from "../Card";
import CatalogueTabs from "../Catalogue/CatalogueTabs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Rings = (data) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [keys, setKeys] = useState([]);
  const [isFixed, setIsFixed] = useState(false); // State to handle fixed class
  const { productcategory } = useParams();
  const categorydata = data.data[productcategory];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data && data.data && categorydata) {
          const keys = Object.keys(categorydata);
          const startIdx = (currentPage - 1) * 9;
          const endIdx = startIdx + 9;
          const newKeys = keys.slice(startIdx, endIdx);
          const newProducts = keys.slice(startIdx, endIdx).map((key) => categorydata[key]);

          setProducts((prevProducts) => [...newProducts, ...prevProducts]);
          setKeys((previouskeys) => [...previouskeys, ...newKeys.reverse()]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [data, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = document.documentElement;

      // Adjust 80 to the height of your navbar
      if (scrollTop > 80) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

      const { scrollHeight, clientHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 150 && !loading) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  return (
    <div className="mainbody">
      <br />
      <br />
      <br />
      <br />
      <h1 className="mainheading-text">CATALOGUE</h1>
      <div className={isFixed ? 'fixed-catalogue-tabs' : ''}>
        <CatalogueTabs />
      </div>
      <div className="container" style={{ padding: "6%" }}>
        <div className="row">
          {products.slice().reverse().map((product, index) => (
            <div key={index} className="col-lg-4 col-md-4 col-sm-6 mb-4">       
              <Card data={product} prodid={keys[index]} />
            </div>
          ))}
        </div>
        {loading && (
          <div className='loading'>
            <img className='loadinggif' src={process.env.PUBLIC_URL + "/images/loading.gif"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Rings;
