import './Reducer';
import ActionTypes from './ActionTypes';
import { database }  from "../firebaseConfig";

export const fetchData = () => {
    return dispatch => {
      dispatch(fetchDataStart());
      return database.ref('catalogue').once('value', snap => {
        const invite = snap.val();
        dispatch(fetchDataSuccess(invite))
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchDataFailure());
      });
    }
  }
  
  export const fetchDataStart = () => {
    return {
      type: 'FETCH_DATA_START',
    };
  }
  
  export const fetchDataSuccess = (data) => {
    return {
      type: 'FETCH_DATA_SUCCESS',
    payload: data,
    }
  }
  
  export const fetchDataFailure = (error) => {
    return {
      type: 'FETCH_DATA_FAILURE',
    payload: error,
    };
  }