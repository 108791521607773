import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { ModalHover } from 'react-modal-hover';
import { database, auth } from "../firebaseConfig";
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'

const Rewards = () => {
    const [userData, setUserData] = useState(null);
    const [totalrewards, setRewards] = useState(0);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverOpen2, setPopoverOpen2] = useState(false);

    useEffect(() => {
        // Fetch user data from the Realtime Database
        const currentUser = auth.currentUser;
        if (currentUser) {
            database
                .ref(`users/${currentUser.uid}`)
                .once("value")
                .then((snapshot) => {
                    const data = snapshot.val();
                    setUserData(data);

                    // Calculate the total rewards amount
                    var totalprice = 0;
                    if (data.rewards) {
                        for (var i in data.rewards) {
                            if (data.rewards[i].type == "credit") {
                                totalprice = totalprice + parseInt(data.rewards[i].amount);
                            }
                            else {
                                totalprice = totalprice - parseInt(data.rewards[i].amount);
                            }
                        }
                    }
                    setRewards(totalprice);
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error);
                });
        }
    }, []);
    const toggle = () => {
        setPopoverOpen(!popoverOpen);
    }
    const onHover = () => {
        setPopoverOpen(true);
    }
    const onHoverLeave = () => {
        setPopoverOpen(false);
    }
    const toggle2 = () => {
        setPopoverOpen2(!popoverOpen);
    }
    const onHover2 = () => {
        setPopoverOpen2(true);
    }
    const onHoverLeave2 = () => {
        setPopoverOpen2(false);
    }
    if (userData === null) {
        return <div className='loading'><img className='loadinggif' src={process.env.PUBLIC_URL + "/images/loading.gif"} /></div>;
    }

    return (
        <div style={{ padding: "5%" }}>
            <h6 className='subheader-text'>YOUR</h6>
            <h1 className='mainheading-text'>REWARDS</h1>
            <h2 className='rewardspoints-text'>{totalrewards}</h2>

            <div class="container" className="rewardsprogressdiv" style={{ padding: "6%" }}>
                <div class="row">
                    <div class="col-4 justify-content-start">
                    </div>
                    <div class="col-4" align="center">
                        <img id="Popover1" onMouseEnter={onHover}
                            onMouseLeave={onHoverLeave} className="rewardsimage" src={process.env.PUBLIC_URL + "/images/ipad.png"} />
                        <Popover
                            placement="top"
                            isOpen={popoverOpen}
                            target="Popover1"
                            toggle={toggle}
                        >
                            <PopoverHeader className="popoverHeader">Ipad Pro</PopoverHeader>
                            <PopoverBody>
                                <center>
                                    <img className="rewardspopupimage" src={process.env.PUBLIC_URL + "/images/ipad.png"} />
                                </center>
                                <b>Spcification</b>
                                <hr></hr>
                                <i><b>Storage</b> : 128GB</i>
                                <br></br>
                                <i><b>RAM</b> : 16GB</i>
                                <hr></hr>
                                Score 1,00,00,000 points to get me.
                            </PopoverBody>
                        </Popover>
                    </div>
                    <div class="col-4" align="end">
                        <img id="Popover2" onMouseEnter={onHover2} onMouseLeave={onHoverLeave2} className="rewardsimage" src={process.env.PUBLIC_URL + "/images/iphone.png"} />
                        <Popover
                            placement="top"
                            isOpen={popoverOpen2}
                            target="Popover2"
                            toggle={toggle2}
                        >
                            <PopoverHeader className="popoverHeader">Iphone 14 Pro</PopoverHeader>
                            <PopoverBody>
                                <center>
                                    <img className="rewardspopupimage" src={process.env.PUBLIC_URL + "/images/iphone.png"} />
                                </center>
                                <b>Spcification</b>
                                <hr></hr>
                                <i><b>Storage</b> : 128GB</i>
                                <br></br>
                                <i><b>RAM</b> : 16GB</i>
                                <hr></hr>
                                Score 2,00,00,000 points to get me.
                            </PopoverBody>
                        </Popover>
                    </div>
                    <ProgressBar completed={totalrewards} maxCompleted={20000000} height="10px" animateOnRender={true} isLabelVisible={false} baseBgColor={"#032437"} bgColor={"#EDE9E6"} />
                    <br></br>
                    <div class="col-4 justify-content-start">
                        <h6 className="rewardsprogresstext">0</h6>
                    </div>
                    <div class="col-4" align="center">
                        <h6 className="rewardsprogresstext">1,00,00,000</h6>
                    </div>
                    <div class="col-4" align="end">
                        <h6 className="rewardsprogresstext">2,00,00,000</h6>
                    </div>
                </div>
                <br></br>
                <div class="row">

                    {userData.rewards ? <React.Fragment>
                        {Object.keys(userData.rewards).reverse().map(id => {

                            return (
                                <React.Fragment>
                                    {userData.rewards[id].type === "credit" ? (
                                        <React.Fragment>
                                            <div className="rewardsdetails">
                                                <div class="col-8">
                                                    <span style={{ display: "flex", alignItems: "center" }}>
                                                    <h5 className="rewardsprogresstext" style={{ color: "green" }}>+ &nbsp;</h5>
                                                        <h5 className="rewardsprogresstext">{userData.rewards[id].amount}</h5>
                                                    </span>
                                                </div>
                                                <div class="col-4" align="end">
                                                    <h6 className="rewardsdatetext">{userData.rewards[id].date}</h6>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) :
                                    <React.Fragment>
                                    <div className="rewardsdetails">
                                        <div class="col-8">
                                            <span style={{ display: "flex", alignItems: "center" }}>
                                            <h5 className="rewardsprogresstext" style={{ color: "red" }}>- &nbsp;</h5>
                                                <h5 className="rewardsprogresstext">{userData.rewards[id].amount}</h5>
                                            </span>
                                        </div>
                                        <div class="col-4" align="end">
                                            <h6 className="rewardsdatetext">{userData.rewards[id].date}</h6>
                                        </div>
                                    </div>
                                </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                        :
                        <React.Fragment>
                            <center >
                                <h6 className='subheader-text'>No Reward Points yet? Give us your first order and let the Reward cycle begin.</h6>
                            </center>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
};

export default Rewards;