import React, { useState } from "react";
import './modal.css';

const Modal = ({ image,alt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="modalimgcontainer">
      <img onClick={openModal} src={image} alt={alt} className="fullmodalimage" />
      {isOpen ? (
        <div className="mainmodal" onClick={closeModal}>
          <div className="modalcontent">
            <img className="modalimage" src={image} alt="" onClick={closeModal}  />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Modal;
