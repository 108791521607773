import React, { useRef, useEffect, useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import Sequence from "../../Sequence";
import NewArrivals from './NewArrivals';
import Services from './Services';
import About from './About';
import HomepageCatalogue from './HomepageCatalogue';
import { Element } from 'react-scroll';
import ProgressBars from "./ProgressBars";
import { database,auth } from "../firebaseConfig";
const Scroller = ({data}) => {
  const [userData, setUserData] = useState(null);
  const ref = useRef();
  useEffect(() => {
    // Fetch user data from the Realtime Database
    const currentUser = auth.currentUser;
    if (currentUser) {
      database
        .ref(`users/${currentUser.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setUserData(data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);
  return (
    <div className="mainbody">
      <Controller>
        <Scene duration="200%" triggerHook="onLeave" pin>
          {progress => (
            <div style={{ height: "100vh", position: "relative" }}>
              <Sequence ref={ref} progress={progress} />
            </div>
          )}
        </Scene>
      </Controller>
      <NewArrivals data = {data} />
      <Element id="services"></Element>
      <Services />
      <Element id="aboutUs"></Element>
      <About data = {data} />
      <ProgressBars />
      <HomepageCatalogue data = {data} />

    </div>
  );
};
export default Scroller;