import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const Card = ({ data, prodid }) => {
  if (data) {
    return (
      <div class="card">
        <Link to={`/catalogue/${data.category}/${data.category}/${prodid}`}><img src={data.image} class="card-img-top" alt="Card Image" /></Link>
        <div class="card-body">
          <h6 class="card-title text-center" className='cardtitle'>{data.name}</h6>

          {data.category === "rings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Rings</p>
            </React.Fragment>
          ) : null}

          {data.category === "bracelets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Bracelets</p>
            </React.Fragment>
          ) : null}

          {data.category === "pendantsets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Pendant Sets</p>
            </React.Fragment>
          ) : null}

          {data.category === "earings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Earings</p>
            </React.Fragment>
          ) : null}

          {data.category === "necklaces" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Necklaces</p>
            </React.Fragment>
          ) : null}
          {data.category === "necklaceset" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Necklace Set</p>
            </React.Fragment>
          ) : null}
          {data.category === "tanmaniya" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Tanmaniya</p>
            </React.Fragment>
          ) : null}

          {data.category === "pendants" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.gender}|Pendants</p>
            </React.Fragment>
          ) : null}
        </div>
      </div>

    );
  }

};

export default Card;
