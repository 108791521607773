import { connect } from 'react-redux';
import Main from './Main';
import { fetchData } from './Redux/ReduxAction';

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isLoading: state.data.isLoading,
    error: state.data.error,
  };
};
  


const MainContainer = connect(mapStateToProps, { fetchData }) (Main);

export default MainContainer;