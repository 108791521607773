import A0 from "./ezgif-frame-001.jpg";
import A1 from "./ezgif-frame-002.jpg";
import A2 from "./ezgif-frame-003.jpg";
import A3 from "./ezgif-frame-004.jpg";
import A4 from "./ezgif-frame-005.jpg";
import A5 from "./ezgif-frame-006.jpg";
import A6 from "./ezgif-frame-007.jpg";
import A7 from "./ezgif-frame-008.jpg";
import A8 from "./ezgif-frame-009.jpg";
import A9 from "./ezgif-frame-010.jpg";

import A10 from "./ezgif-frame-011.jpg";
import A11 from "./ezgif-frame-012.jpg";
import A12 from "./ezgif-frame-013.jpg";
import A13 from "./ezgif-frame-014.jpg";
import A14 from "./ezgif-frame-015.jpg";
import A15 from "./ezgif-frame-016.jpg";
import A16 from "./ezgif-frame-017.jpg";
import A17 from "./ezgif-frame-018.jpg";
import A18 from "./ezgif-frame-019.jpg";
import A19 from "./ezgif-frame-020.jpg";

import A20 from "./ezgif-frame-021.jpg";
import A21 from "./ezgif-frame-022.jpg";
import A22 from "./ezgif-frame-023.jpg";
import A23 from "./ezgif-frame-024.jpg";
import A24 from "./ezgif-frame-025.jpg";
import A25 from "./ezgif-frame-026.jpg";
import A26 from "./ezgif-frame-027.jpg";
import A27 from "./ezgif-frame-028.jpg";
import A28 from "./ezgif-frame-029.jpg";
import A29 from "./ezgif-frame-030.jpg";

import A30 from "./ezgif-frame-031.jpg";
import A31 from "./ezgif-frame-032.jpg";
import A32 from "./ezgif-frame-033.jpg";
import A33 from "./ezgif-frame-034.jpg";
import A34 from "./ezgif-frame-035.jpg";
import A35 from "./ezgif-frame-036.jpg";
import A36 from "./ezgif-frame-037.jpg";
import A37 from "./ezgif-frame-038.jpg";
import A38 from "./ezgif-frame-039.jpg";
import A39 from "./ezgif-frame-040.jpg";

import A40 from "./ezgif-frame-041.jpg";
import A41 from "./ezgif-frame-042.jpg";
import A42 from "./ezgif-frame-043.jpg";
import A43 from "./ezgif-frame-044.jpg";
import A44 from "./ezgif-frame-045.jpg";
import A45 from "./ezgif-frame-046.jpg";
import A46 from "./ezgif-frame-047.jpg";
import A47 from "./ezgif-frame-048.jpg";
import A48 from "./ezgif-frame-049.jpg";
import A49 from "./ezgif-frame-050.jpg";

import A50 from "./ezgif-frame-051.jpg";
import A51 from "./ezgif-frame-052.jpg";
import A52 from "./ezgif-frame-053.jpg";
import A53 from "./ezgif-frame-054.jpg";
import A54 from "./ezgif-frame-055.jpg";
import A55 from "./ezgif-frame-056.jpg";
import A56 from "./ezgif-frame-057.jpg";
import A57 from "./ezgif-frame-058.jpg";
import A58 from "./ezgif-frame-059.jpg";
import A59 from "./ezgif-frame-060.jpg";

import A60 from "./ezgif-frame-061.jpg";
import A61 from "./ezgif-frame-062.jpg";
import A62 from "./ezgif-frame-063.jpg";
import A63 from "./ezgif-frame-064.jpg";
import A64 from "./ezgif-frame-065.jpg";
import A65 from "./ezgif-frame-066.jpg";
import A66 from "./ezgif-frame-067.jpg";
import A67 from "./ezgif-frame-068.jpg";
import A68 from "./ezgif-frame-069.jpg";
import A69 from "./ezgif-frame-070.jpg";

import A70 from "./ezgif-frame-071.jpg";
import A71 from "./ezgif-frame-072.jpg";
import A72 from "./ezgif-frame-073.jpg";
import A73 from "./ezgif-frame-074.jpg";
import A74 from "./ezgif-frame-075.jpg";
import A75 from "./ezgif-frame-076.jpg";
import A76 from "./ezgif-frame-077.jpg";
import A77 from "./ezgif-frame-078.jpg";
import A78 from "./ezgif-frame-079.jpg";
import A79 from "./ezgif-frame-080.jpg";

import A80 from "./ezgif-frame-081.jpg";
import A81 from "./ezgif-frame-082.jpg";
import A82 from "./ezgif-frame-083.jpg";
import A83 from "./ezgif-frame-084.jpg";
import A84 from "./ezgif-frame-085.jpg";
import A85 from "./ezgif-frame-086.jpg";
import A86 from "./ezgif-frame-087.jpg";
import A87 from "./ezgif-frame-088.jpg";
import A88 from "./ezgif-frame-089.jpg";
import A89 from "./ezgif-frame-090.jpg";

import A90 from "./ezgif-frame-091.jpg";
import A91 from "./ezgif-frame-092.jpg";
import A92 from "./ezgif-frame-093.jpg";
import A93 from "./ezgif-frame-094.jpg";
import A94 from "./ezgif-frame-095.jpg";
import A95 from "./ezgif-frame-096.jpg";
import A96 from "./ezgif-frame-097.jpg";
import A97 from "./ezgif-frame-098.jpg";
import A98 from "./ezgif-frame-099.jpg";
import A99 from "./ezgif-frame-100.jpg";

import A100 from "./ezgif-frame-101.jpg";
import A101 from "./ezgif-frame-102.jpg";
import A102 from "./ezgif-frame-103.jpg";
import A103 from "./ezgif-frame-104.jpg";
import A104 from "./ezgif-frame-105.jpg";
import A105 from "./ezgif-frame-106.jpg";
import A106 from "./ezgif-frame-107.jpg";
import A107 from "./ezgif-frame-108.jpg";
import A108 from "./ezgif-frame-109.jpg";
import A109 from "./ezgif-frame-110.jpg";

import A110 from "./ezgif-frame-111.jpg";
import A111 from "./ezgif-frame-112.jpg";
import A112 from "./ezgif-frame-113.jpg";
import A113 from "./ezgif-frame-114.jpg";
import A114 from "./ezgif-frame-115.jpg";
import A115 from "./ezgif-frame-116.jpg";
import A116 from "./ezgif-frame-117.jpg";
import A117 from "./ezgif-frame-118.jpg";
import A118 from "./ezgif-frame-119.jpg";
import A119 from "./ezgif-frame-120.jpg";

import A120 from "./ezgif-frame-121.jpg";
import A121 from "./ezgif-frame-122.jpg";
import A122 from "./ezgif-frame-123.jpg";
import A123 from "./ezgif-frame-124.jpg";
import A124 from "./ezgif-frame-125.jpg";
import A125 from "./ezgif-frame-126.jpg";
import A126 from "./ezgif-frame-127.jpg";
import A127 from "./ezgif-frame-128.jpg";
import A128 from "./ezgif-frame-129.jpg";
import A129 from "./ezgif-frame-130.jpg";

import A130 from "./ezgif-frame-131.jpg";
import A131 from "./ezgif-frame-132.jpg";
import A132 from "./ezgif-frame-133.jpg";
import A133 from "./ezgif-frame-134.jpg";
import A134 from "./ezgif-frame-135.jpg";
import A135 from "./ezgif-frame-136.jpg";
import A136 from "./ezgif-frame-137.jpg";
import A137 from "./ezgif-frame-138.jpg";
import A138 from "./ezgif-frame-139.jpg";
import A139 from "./ezgif-frame-140.jpg";

import A140 from "./ezgif-frame-141.jpg";
import A141 from "./ezgif-frame-142.jpg";
import A142 from "./ezgif-frame-143.jpg";
import A143 from "./ezgif-frame-144.jpg";
import A144 from "./ezgif-frame-145.jpg";
import A145 from "./ezgif-frame-146.jpg";
import A146 from "./ezgif-frame-147.jpg";
import A147 from "./ezgif-frame-148.jpg";
import A148 from "./ezgif-frame-149.jpg";
import A149 from "./ezgif-frame-150.jpg";

import A150 from "./ezgif-frame-151.jpg";
import A151 from "./ezgif-frame-152.jpg";
import A152 from "./ezgif-frame-153.jpg";
import A153 from "./ezgif-frame-154.jpg";
import A154 from "./ezgif-frame-155.jpg";
import A155 from "./ezgif-frame-156.jpg";
import A156 from "./ezgif-frame-157.jpg";
import A157 from "./ezgif-frame-158.jpg";
import A158 from "./ezgif-frame-159.jpg";
import A159 from "./ezgif-frame-160.jpg";

import A160 from "./ezgif-frame-161.jpg";
import A161 from "./ezgif-frame-162.jpg";
import A162 from "./ezgif-frame-163.jpg";
import A163 from "./ezgif-frame-164.jpg";
import A164 from "./ezgif-frame-165.jpg";
import A165 from "./ezgif-frame-166.jpg";
import A166 from "./ezgif-frame-167.jpg";
import A167 from "./ezgif-frame-168.jpg";
import A168 from "./ezgif-frame-169.jpg";
import A169 from "./ezgif-frame-170.jpg";

import A170 from "./ezgif-frame-171.jpg";
import A171 from "./ezgif-frame-172.jpg";
import A172 from "./ezgif-frame-173.jpg";
import A173 from "./ezgif-frame-174.jpg";
import A174 from "./ezgif-frame-175.jpg";
import A175 from "./ezgif-frame-176.jpg";
import A176 from "./ezgif-frame-177.jpg";
import A177 from "./ezgif-frame-178.jpg";
import A178 from "./ezgif-frame-179.jpg";
import A179 from "./ezgif-frame-180.jpg";

import A180 from "./ezgif-frame-181.jpg";
import A181 from "./ezgif-frame-182.jpg";
import A182 from "./ezgif-frame-183.jpg";
import A183 from "./ezgif-frame-184.jpg";
import A184 from "./ezgif-frame-185.jpg";
import A185 from "./ezgif-frame-186.jpg";
import A186 from "./ezgif-frame-187.jpg";
import A187 from "./ezgif-frame-188.jpg";
import A188 from "./ezgif-frame-189.jpg";
import A189 from "./ezgif-frame-190.jpg";

import A190 from "./ezgif-frame-191.jpg";
import A191 from "./ezgif-frame-192.jpg";
import A192 from "./ezgif-frame-193.jpg";

export const AirPods = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,

  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,
  A51,
  A52,
  A53,
  A54,
  A55,
  A56,
  A57,
  A58,
  A59,

  A60,
  A61,
  A62,
  A63,
  A64,
  A65,
  A66,
  A67,
  A68,
  A69,

  A70,
  A71,
  A72,
  A73,
  A74,
  A75,
  A76,
  A77,
  A78,
  A79,

  A80,
  A81,
  A82,
  A83,
  A84,
  A85,
  A86,
  A87,
  A88,
  A89,

  A90,
  A91,
  A92,
  A93,
  A94,
  A95,
  A96,
  A97,
  A98,
  A99,

  A100,
  A101,
  A102,
  A103,
  A104,
  A105,
  A106,
  A107,
  A108,
  A109,

  A110,
  A111,
  A112,
  A113,
  A114,
  A115,
  A116,
  A117,
  A118,
  A119,

  A120,
  A121,
  A122,
  A123,
  A124,
  A125,
  A126,
  A127,
  A128,
  A129,

  A130,
  A131,
  A132,
  A133,
  A134,
  A135,
  A136,
  A137,
  A138,
  A139,

  A140,
  A141,
  A142,
  A143,
  A144,
  A145,
  A146,
  A147,
  A148,
  A149,

  A150,
  A151,
  A152,
  A153,
  A154,
  A155,
  A156,
  A157,
  A158,
  A159,

  A160,
  A161,
  A162,
  A163,
  A164,
  A165,
  A166,
  A167,
  A168,
  A169,

  A170,
  A171,
  A172,
  A173,
  A174,
  A175,
  A176,
  A177,
  A178,
  A179,

  A180,
  A181,
  A182,
  A183,
  A184,
  A185,
  A186,
  A187,
  A188,
  A189,
  A190,
  A191,
  A192
);
