import React, { useEffect, useState } from 'react';
import { Certificate, HandCoins, SketchLogo, Users } from "@phosphor-icons/react";
import aboutsection1 from "../../static/images/aboutsection1.jpg"
import aboutsection2 from "../../static/images/aboutsection2.jpg"
import aboutsection3 from "../../static/images/aboutsection3.jpg"

const About = () => {

  return (
    <div >
      <h6 className='subheader-text' id='aboutus'>ABOUT</h6>
      <h1 className='mainheading-text'>DREAMS JEWELS</h1>
      <div class="container" style={{ padding: "6%" }}>
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <p className='aboutparagraph'>DREAMS JEWELS was founded in 1992 by Kalpesh Shah, we are passionate about delivering exceptional quality and exquisite designs to our valued customers. As a leading wholesale supplier of fine jewellery, we take pride in offering a wide range of high-quality products that meet the diverse needs and preferences of our clients.
              With years of experience in the industry, we have established ourselves as a trusted name, known for our commitment to excellence and customer satisfaction. Our team of skilled artisans and designers work tirelessly to create stunning jewellery pieces that capture the essence of elegance and beauty.
              Quality is at the heart of everything we do. We carefully source the finest materials, including precious metals, diamonds and gemstones, to ensure that each piece meets the highest standards. Our attention to detail and rigorous quality control processes guarantee that every item leaving our workshop is flawless and enduring.</p>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <div className='about-image-wrapper '>
                  <div className='about-image-container'>
                    <img src={aboutsection1} class="img-fluid" />
                    <div class="overlay">
                      <h3 className='aboutimageoverlaytitle'>Rings</h3>
                      <p className='aboutimageoverlaysubtitle' >Women|Rings</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="row image-wrapper mb-3">

                  <div className='image-container'>
                    <img src={aboutsection2} class="img-fluid" />
                    <div class="overlay">
                      <h3 className='imageoverlaytitle'>Bracelet</h3>
                      <p className='imageoverlaysubtitle' >Women|Bracelet</p>
                    </div>
                  </div>
                </div>
                <div class="row image-wrapper">
                  <div className='image-container'>
                    <img src={aboutsection3} class="img-fluid" />
                    <div class="overlay">
                      <h3 className='imageoverlaytitle'>Bracelet</h3>
                      <p className='imageoverlaysubtitle' >Women|Bracelet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default About;
