import React from 'react';
import { createStore } from 'redux';
import rootReducer from './RootReducer'; // Your root reducer
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from "redux-logger";
// const store = createStore(rootReducer);
const logger = createLogger();

const store = createStore(rootReducer,
  {},
  applyMiddleware(thunk, logger)
);

export default store;