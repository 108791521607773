import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Button,
    Input
} from 'reactstrap';
import { auth } from '../firebaseConfig';

const Login = ({ onUpdate }) => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isTouchedEmail, setisTouchedEmail] = useState(false);
    const [isTouchedPassword, setisTouchedPassword] = useState(false);
    const [loginInvalid, setloginInvalid] = useState(false);
    const [emailverified, setlemailverified] = useState(true);
    const [currentUser,setcurrenUser] = useState("");
    const handleChangeEmail = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);

        const isValid = /\S+@\S+\.\S+/.test(emailValue);
        setIsValidEmail(isValid);
    };
    const handleBlurEmail = () => {
        setisTouchedEmail(true);
    };

    const handleChangePassword = (event) => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);

    };
   
    const newverificationlink = async() => {
        console.log("clicked verification");
        await currentUser.user.sendEmailVerification()
        .then(() => {
            alert("verification link sent");
            // setlemailverified(false);
        })
        .catch((e) => {
            alert(e);
        })
    };
    const onLogin = async(e) => {
        if(isValidEmail){
        // await auth.
        e.preventDefault();
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                console.log(auth.currentUser);
                if(auth.currentUser.emailVerified){
                    const user = userCredential.user;
                    const newValue = true;
                    setLoggedIn(true);
                    onUpdate(true);
                     history.push("/home");
                     setlemailverified(true);
                }
                else{
                    setLoggedIn(false);
                    onUpdate(false);
                    setcurrenUser(userCredential);
                    setlemailverified(false);
                    setloginInvalid(false);
                    // auth.signOut();
                }
                // Signed in
                
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setloginInvalid(true);
                console.log(errorCode, errorMessage)
            });
        }
    }
    return (
        <div className="mainbodyimg">
            <div class="conatiner">
                <div class="row">
                    <div class="col-12">
                        <div className='solidbglogin'>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <img src={process.env.PUBLIC_URL + "/images/loginimg.png"} class="img-fluid" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                    <h6 className='subheader-text'>LOGIN</h6>
                                    <br></br>
                                    <Form style={{padding : "5%"}}>
                                        <FormGroup floating>

                                            <Input
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                value={email}
                                                onChange={handleChangeEmail}
                                                onBlur={handleBlurEmail}
                                                valid={isTouchedEmail && isValidEmail}
                                                invalid={isTouchedEmail && !isValidEmail}
                                            />
                                            <Label for="email">
                                                Email
                                            </Label>

                                            <FormFeedback valid>
                                                Sweet! that email is valid
                                            </FormFeedback>

                                            <FormFeedback invalid>
                                                Please enter a valid email
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup floating>
                                            <Input
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                value={password}
                                                onChange={handleChangePassword}
                                            />
                                            <Label for="password">Password</Label>
                                        </FormGroup>
                                        <center>
                                            {loginInvalid ? 
                                            <React.Fragment>
                                                <p className='invalidcred'>Invalid Credentials</p>
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                            {emailverified? 
                                        null :
                                        <React.Fragment>
                                        <p className='errortext'>Please verify your email first to continue.<a className='errortext' href='#' onClick={newverificationlink}>Click here to get a new link</a></p>
                                        </React.Fragment>
                                        }
                                        <p className='logintext'>Haven't signed up yet? <Link className='logintextlink' to="/signup">Sign up here</Link> .</p>
                                        
                                    
                                        <button className="loginbtn"  onClick={onLogin}  >
                                            Login
                                        </button>
                                        </center>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       
    )
}

export default Login
