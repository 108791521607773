import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const NewArrivals = ({data}) => {

  return (
<div>
  <h6 className='subheader-text'>EXPLORE</h6>
  <h1 className='mainheading-text'>NEW ARRIVALS</h1>
    <div class="container" style={{padding: "6%"}}>
  <div class="row">
    {data.newarrivals.newarrival1 ?
    <div class="col-lg-8 col-md-8 col-sm-6 mb-3">
      <div class="image-wrapper">
      <div className='image-container'>
    <img src={data.newarrivals.newarrival1.image} class="img-fluid" />
    <Link  to={`/catalogue/${data.newarrivals.newarrival1.category}/${data.newarrivals.newarrival1.category}/${data.newarrivals.newarrival1.newarrivalsid}`}>
    <div class="overlay">
      <h3 className='imageoverlaytitle'>{data.newarrivals.newarrival1.name}</h3>
      {data.newarrivals.newarrival1.category === "rings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival1.gender}|Rings</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival1.category === "bracelets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival1.gender}|Bracelets</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival1.category === "pendantsets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival1.gender}|Pendant Sets</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival1.category === "earings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival1.gender}|Earings</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival1.category === "necklaces" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival1.gender}|Necklaces</p>
            </React.Fragment>
          ) : null}
    </div>
    </Link>
</div>
      </div>
    </div>
    : null}
    <div class="col-lg-4 col-md-4 col-sm-6">
    {data.newarrivals.newarrival2 ?
      <div class="row image-wrapper mb-3">
      <div className='image-container'>
    <img src={data.newarrivals.newarrival2.image} class="img-fluid" />
    <Link  to={`/catalogue/${data.newarrivals.newarrival2.category}/${data.newarrivals.newarrival2.category}/${data.newarrivals.newarrival2.newarrivalsid}`}>
        <div class="overlay">
      <h3 className='imageoverlaytitle'>{data.newarrivals.newarrival2.name}</h3>
      {data.newarrivals.newarrival2.category === "rings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival2.gender}|Rings</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival2.category === "bracelets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival2.gender}|Bracelets</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival2.category === "pendantsets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival2.gender}|Pendant Sets</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival2.category === "earings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival2.gender}|Earings</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival2.category === "necklaces" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival2.gender}|Necklaces</p>
            </React.Fragment>
          ) : null}
    </div>
    </Link>
</div>
      </div>
      : null}
    {data.newarrivals.newarrival3 ?
      <div class="row image-wrapper">
      <div className='image-container'>
    <img src={data.newarrivals.newarrival3.image} class="img-fluid" />
    <Link className='newaarivalslink'  to={`/catalogue/${data.newarrivals.newarrival3.category}/${data.newarrivals.newarrival3.category}/${data.newarrivals.newarrival3.newarrivalsid}`}>
        <div class="overlay">
      <h3 className='imageoverlaytitle'>{data.newarrivals.newarrival3.name}</h3>
      {data.newarrivals.newarrival3.category === "rings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival3.gender}|Rings</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival3.category === "bracelets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival3.gender}|Bracelets</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival3.category === "pendantsets" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival3.gender}|Pendant Sets</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival3.category === "earings" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival3.gender}|Earings</p>
            </React.Fragment>
          ) : null}

          {data.newarrivals.newarrival3.category === "necklaces" ? (
            <React.Fragment>
              <p class="card-text text-center" className='cardtext'>{data.newarrivals.newarrival3.gender}|Necklaces</p>
            </React.Fragment>
          ) : null}
    </div>
    </Link>

</div>
      </div>
      : null}
    </div>
  </div>
</div>
</div>

  );
};

export default NewArrivals;
