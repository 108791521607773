import React, { useEffect, useState } from 'react';
import catalogue1 from "../../static/images/catalogue1.jpg"
import { Link } from 'react-router-dom';

const SuggestionCard = ({ product, prodId }) => {

  return (
    <div class="card" className='suggestioncard'>
      <Link to={`/catalogue/${product.category}/${product.category}/${prodId}`}><img src={product.image} class="card-img-top" alt="Card Image" /></Link>
      <div class="card-body">
        <br></br>
        <h6 class="card-title text-center" className='cardtitle'>{product.name}</h6>
        {product.category === "rings" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Rings</p>
          </React.Fragment>
        ) : null}

        {product.category === "bracelets" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Bracelets</p>
          </React.Fragment>
        ) : null}

        {product.category === "pendantsets" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Pendant Sets</p>
          </React.Fragment>
        ) : null}

        {product.category === "earings" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Earings</p>
          </React.Fragment>
        ) : null}

        {product.category === "necklaces" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Necklaces</p>
          </React.Fragment>
        ) : null}

{product.category === "necklaceset" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Necklace Set</p>
          </React.Fragment>
        ) : null}

        {product.category === "tanmaniya" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Tanmaniya</p>
          </React.Fragment>
        ) : null}

        {product.category === "pendants" ? (
          <React.Fragment>
            <p class="card-text text-center" className='cardtext'>{product.gender}|Pendants</p>
          </React.Fragment>
        ) : null}
      </div>
    </div>

  );
};

export default SuggestionCard;
