import './App.css';
import Main from './components/Main';
import {BrowserRouter} from 'react-router-dom';
import MainContainer from './components/MainContainer';
function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <MainContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
