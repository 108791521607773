import React from "react";
import { useHistory } from 'react-router-dom';
import { CaretRight } from "@phosphor-icons/react";
const ViewCatalogueBtn = () => {
  const history = useHistory();
    const handleClick = () => {
        history.push('/catalogue/rings'); // Replace '/your-route' with the desired route path
    };
    return (
        <button className="primarybutton" onClick={handleClick}>VIEW CATALOGUE <CaretRight />
        </button>
    );

};
export default ViewCatalogueBtn;