import React, { useEffect, useState } from 'react';
import Header from './Navbar.js';
import Scroller from '../components/HomePage/MainPage.js';
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from './Footer.js';
import Rings from '../components/Catalogue/Rings.js';
import SingleProduct from '../components/SingleProduct/SingleProduct.js';
import 'firebase/database';
import { connect } from 'react-redux';
import { fetchData } from './Redux/ReduxAction.js';
import Signup from './Login/Signup.js';
import Login from './Login/Login.js';
import Rewards from './Rewards/Rewards.js';
import { auth } from "./firebaseConfig.js";

const Main = ({ data, isLoading, error, fetchData }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const updateLoggenIn = (newValue) => {
    setLoggedIn(newValue);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, [fetchData]);


  if (isLoading) {
    return <div className='loading'><img className='loadinggif' src={process.env.PUBLIC_URL + "/images/loading.gif"} /></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data) {
    return (
      <div >

        <Header onUpdate={updateLoggenIn} loggedIn={loggedIn} />
        <Switch>

          <Route exact path="/home" component={() => <Scroller data={data} />} />
          {auth.currentUser ?
            null :
            <Route exact path="/signup" component={Signup} />
          }
          {auth.currentUser ?
            <Route exact path="/rewards" component={Rewards} />
            :
            null
          }
          {auth.currentUser ?
            null :
            <Route exact path="/login" component={() => <Login onUpdate={updateLoggenIn} />} />
          }
          <Route exact path="/catalogue">
            <Redirect to="/catalogue/rings/rings" />
          </Route>
          <Route exact path="/catalogue/rings/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/bracelets/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/tanmaniya/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/pendants/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/pendantsets/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/earings/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/necklaces/:productcategory" component={() => <Rings data={data} />} />
          <Route exact path="/catalogue/necklaceset/:productcategory" component={() => <Rings data={data} />} />
          <Route path="/catalogue/:productcategory/:productcategory/:dataId" component={() => <SingleProduct data={data} />} />
          <Route path="*" render={() => <Redirect to="/home" />} />

        </Switch>
        {/* <Redirect to="/home" /> */}
        <Footer />
      </div>
    );
  }

}
export default Main;