import React, {useEffect} from "react";
import ProgressBar from 'react-customizable-progressbar'

const ProgressBars = () => {
    
    return (
        <div>
            <div class="container" style={{ padding: "6%" }}>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-3">

                    <center>
                        <h4 className="progressbarheading">Trusted Clients</h4>
                        <ProgressBar
                            progress={95}
                            radius={100}
                            strokeWidth={23}
                            strokeColor="#669ea1"
                            trackStrokeColor="#011520"
                            
                         >
                          <div className="indicator">
                <div>10+</div>
            </div>
                            </ProgressBar>
                            </center>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-3">

                    <center>
                        <h4 className="progressbarheading">Orders Received</h4>
                        <ProgressBar
                            progress={95}
                            radius={100}
                            strokeWidth={23}
                            strokeColor="#669ea1"
                            trackStrokeColor="#011520"
                            
                         >
                          <div className="indicator">
                <div>50,000+</div>
            </div>
                            </ProgressBar>
                            </center>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-3">

                    <center>
                        <h4 className="progressbarheading">Trusted Years</h4>
                        <ProgressBar
                            progress={95}
                            radius={100}
                            strokeWidth={23}
                            strokeColor="#669ea1"
                            trackStrokeColor="#011520"
                            
                         >
                          <div className="indicator">
                <div>30+</div>
            </div>
                            </ProgressBar>
                            </center>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgressBars;
