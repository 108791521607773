import React from "react";
import since1992 from "../static/images/since1992.png"
import { InstagramLogo, FacebookLogo, LinkedinLogo } from "@phosphor-icons/react";

import { Link } from "react-router-dom";
const Footer = () => {

    return (
        <>
            <div class="container" className="footer">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <center>
                            <h3 className="footerlogotext">DREAMS JEWELS</h3>
                        </center>
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12 col-sm-12 d-flex align-items-center justify-content-center">
                                <img src={since1992} class="img-fluid" style={{ paddingLeft: "30px", paddingRight: "30px" }} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <h6 className="footertextheader">CATALOGUE</h6>
                        <div class="row">
                            <div class="col-6">
                                <Link className="footerlink" to={'/catalogue/rings/rings'}><h6 className="cataloguesubheaders">RINGS</h6></Link>
                                <Link className="footerlink" to={'/catalogue/bracelets/bracelets'}><h6 className="cataloguesubheaders">BRACELETS</h6></Link>
                                <Link className="footerlink" to={'/catalogue/pendantsets/pendantsets'}><h6 className="cataloguesubheaders">PENDANT SETS</h6></Link>
                                {/* <Link className="footerlink" to={'/catalogue/tanmaniya/tanmaniya'}><h6 className="cataloguesubheaders">TANMANIYA</h6></Link>  */}
                            </div>
                            <div class="col-6">
                                <Link className="footerlink" to={'/catalogue/pendants/pendants'}><h6 className="cataloguesubheaders">PENDANTS</h6></Link>
                                <Link className="footerlink" to={'/catalogue/necklaces/necklaces'}><h6 className="cataloguesubheaders">NECKLACES</h6></Link>
                                <Link className="footerlink" to={'/catalogue/earings/earings'}><h6 className="cataloguesubheaders">EARINGS</h6></Link>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-3">
                        <h6 className="footertextheader">CONTACT</h6>
                        <div class="row">
                            <div class="col-4">
                                <a className="footerlink" href="https://www.instagram.com/dreamsjewels/"><InstagramLogo className="contactlogos" size={25} /></a>
                            </div>
                            <div class="col-4">
                                <a className="footerlink" href="https://www.instagram.com/dreamsjewels/"><FacebookLogo className="contactlogos" size={25} /></a>
                            </div>
                            <div class="col-4">
                                <a className="footerlink" href="https://www.linkedin.com/company/dreams-jewels/about/"><LinkedinLogo className="contactlogos" size={25} /></a>
                            </div>
                        </div>
                        <h6 className="cataloguesubheaders" style={{ marginTop: "5%" }}>+91 9892153550</h6>
                        <h6 className="cataloguesubheaders">+91 8850286201</h6>
                        <h6 className="cataloguesubheaders">sales@dreamsjewels.in</h6>

                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-3">
                        <h6 className="footertextheader">ADDRESS</h6>
                        <h6 className="cataloguesubheaders">609, Shukra Building
                            <br></br>Diamond Market
                            <br></br>Malad East
                            <br></br>Mumbai 400097</h6>
                    </div>
                </div>
            </div>
            <div className="copyright-footer">
                Designed by  <b style={{marginLeft: "5px"}}> Dhruvil Shah</b>. Copyright © 2022, Dreams Jewels. All Rights reserved.
            </div>
        </>
    );
};

export default Footer;