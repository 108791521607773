import React, { useEffect, useState } from 'react';
import Card from '../Card';
import ViewCatalogueBtn from '../ViewCatalogueBtn';
const HomepageCatalogue = ({data}) => {

  return (
<div>
  <h6 className='subheader-text'>CHECKOUT</h6>
  <h1 className='mainheading-text'>CATALOGUE</h1>
    <div class="container" style={{padding: "6%"}}>
  <div class="row">
  {data.featured ? 
  Object.keys(data.featured).map(id => {

return (
    <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
      <Card data={data.featured[id]} prodid={data.featured[id].featuredid} />
    </div>
)
}):
null}
  </div>
  <div class="row" >
    <center style={{marginTop: "20px"}}>
      <ViewCatalogueBtn />
    </center>
  </div>
</div>
</div>

  );
};

export default HomepageCatalogue;
